<template>
	<div class="puiformheader">
		<v-layout wrap>
			<v-flex xs12 md6 lg3>
				<pui-field :label="this.$t('header.resources.type')" :value="getType"></pui-field>
			</v-flex>
			<v-flex xs12 md6 lg3>
				<pui-field :label="this.$t('header.resources.description')" :value="getDescription"></pui-field>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'resources-form-header',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'resources'
		};
	},
	computed: {
		getDescription() {
			return this.model && this.model.description ? this.model.description : '';
		},
		getType() {
			return this.model && this.model.type ? this.model.type : '';
		}
	}
};
</script>
