<template>
	<v-row class="pui-form-layout">
		<v-col cols="12">
			<pui-field-set :title="$t('form.resources.general')">
				<v-row dense>
					<v-col cols="3">
						<pui-select
							:id="`portid-${modelName}`"
							:attach="`portid-${modelName}`"
							:label="$t('form.resources.portname')"
							toplabel
							clearable
							:disabled="formDisabled || !isCreatingElement"
							v-model="parentObject"
							modelName="port"
							:itemsToSelect="[{ id: parentObject.portid }]"
							:modelFormMapping="{ id: 'portid' }"
							itemValue="id"
							itemText="portname"
							:fixedFilter="filterByPortAuthorityAndPort"
							reactive
						></pui-select>
					</v-col>
					<v-col cols="6">
						<pui-select
							:id="`berthid-${modelName}`"
							:attach="`berthid-${modelName}`"
							:label="$t('form.resources.berthname')"
							toplabel
							clearable
							:disabled="formDisabled || !parentObject.portid"
							v-model="parentObject"
							modelName="berth"
							:itemsToSelect="[{ id: parentObject.berthid }]"
							:modelFormMapping="{ id: 'berthid' }"
							itemValue="id"
							:itemText="(item) => (item.berthsubcode ? `${item.name} - ${item.berthsubcode}` : item.name)"
							:order="{ name: 'asc' }"
							:fixedFilter="filterByPort"
							reactive
						></pui-select>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="3">
						<pui-select
							id="resourcetypeid"
							attach="resourcetypeid"
							:label="$t('form.resources.resourcetypeid')"
							required
							:disabled="formDisabled || !isCreatingElement"
							v-model="parentObject"
							modelName="resourcetype"
							:itemsToSelect="[{ resourcetypeid: parentObject.resourcetypeid }]"
							:modelFormMapping="{ resourcetypeid: 'resourcetypeid' }"
							itemValue="resourcetypeid"
							itemText="name"
							toplabel
							reactive
						></pui-select>
					</v-col>
					<v-col cols="6">
						<pui-text-field
							:id="`description-resources`"
							v-model="parentObject.description"
							:label="$t('form.resources.description')"
							:disabled="formDisabled"
							required
							toplabel
							maxlength="100"
						></pui-text-field>
					</v-col>
					<v-col cols="3">
						<pui-date-field
							v-model="parentObject.disableddate"
							:label="$t('form.resources.disableddate')"
							:disabled="formDisabled"
							toplabel
						></pui-date-field>
					</v-col>
				</v-row>
			</pui-field-set>
		</v-col>
		<v-col cols="8" v-if="!isCreatingElement">
			<pui-field-set :title="$t('form.resources.dynamics')">
				<v-row dense
					><v-col
						v-show="attribute.disabled == 0"
						v-for="(attribute, index) in parentObject.attributes"
						:key="attribute.id"
						cols="12"
						:md="attribute.typeattributeid === 3 ? 12 : 6"
						class="align-self-center"
					>
						<pui-text-field
							v-if="attribute.typeattributeid === 1"
							:id="`dynamic-text-${index}-${modelName}`"
							:label="attribute.description"
							v-model="attribute.attributevalue.valuestring"
							maxlength="100"
							toplabel
							:disabled="formDisabled"
						></pui-text-field>
						<pui-checkbox
							v-else-if="attribute.typeattributeid === 2"
							:id="`dynamic-check-${index}-${modelName}`"
							:label="attribute.description"
							:true-value="true"
							v-model="attribute.attributevalue.valueboolean"
							:false-value="false"
							:disabled="formDisabled"
						></pui-checkbox>
						<pui-text-area
							v-else-if="attribute.typeattributeid === 3"
							:id="`dynamic-textarea-${index}-${modelName}`"
							:label="attribute.description"
							v-model="attribute.attributevalue.valuestring"
							maxlength="250"
							toplabel
							:disabled="formDisabled"
						></pui-text-area>
						<pui-date-field
							v-else-if="attribute.typeattributeid === 4"
							:id="`dynamic-date-${index}-${modelName}`"
							:label="attribute.description"
							v-model="attribute.attributevalue.valuedate"
							toplabel
							:disabled="formDisabled"
						></pui-date-field>
						<pui-select
							v-else-if="attribute.typeattributeid === 5"
							:id="`dynamic-select-${index}-${modelName}`"
							:attach="`dynamic-select-${index}-${modelName}`"
							:label="attribute.description"
							clearable
							toplabel
							:disabled="formDisabled"
							v-model="attribute.attributevalue"
							modelName="typeattributeoptions"
							:itemsToSelect="[{ id: attribute.attributevalue.valueoption }]"
							:modelFormMapping="{ id: 'valueoption' }"
							:itemValue="['id']"
							:itemText="(item) => `${item.description}`"
							:order="{ sort: 'asc' }"
							:fixedFilter="{
								groups: [],
								groupOp: 'and',
								rules: [{ field: 'attributeid', op: 'eq', data: attribute.id }]
							}"
						></pui-select>
					</v-col>
				</v-row>
			</pui-field-set>
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: 'resources-form-main-tab',
	mixins: [],
	components: {},
	data() {
		return {
			modelName: 'resources'
		};
	},
	props: {
		parentObject: {
			type: Object,
			required: true
		},
		formDisabled: {
			type: Boolean,
			required: true
		},
		isCreatingElement: {
			type: Boolean,
			required: true
		}
	},
	methods: {
		afterGetData() {
			console.log(this.parentObject);
		}
	},
	computed: {
		filterByPortAuthorityAndPort() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'portauthorityid', op: 'eq', data: window.localStorage.getItem('workingPortAuthorityId') },
					{ field: 'id', op: 'in', data: this.$store.getters.getPortsIds }
				]
			};
		},
		filterByPort() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'portid', op: 'eq', data: this.parentObject.portid }]
			};
		}
	},
	mounted() {
		if (this.isCreatingElement && this.$route.params.parentpk) {
			this.parentObject.resourcetypeid = JSON.parse(atob(this.$route.params.parentpk)).resourcetypeid;
			if (!window.localStorage.getItem('workingPortsId').includes(',')) {
				this.parentObject.portid = window.localStorage.getItem('workingPortsId');
			}
		}
	}
};
</script>
